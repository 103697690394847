// @ts-nocheck
import React, {useState} from 'react';
import {ScrollView, TouchableOpacity, View, Image} from 'react-native';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import AsterixSwitch from '../../../components/AsterixSwitch';
import tailwind from 'tailwind-rn';
import Icon from 'react-native-vector-icons/Feather';
import {
  DisabledInput,
  Navbar,
  Permissions,
} from '../../../components';
import {styles} from '../../../styles';
import {UnitLink} from '../../../@types/UnitLink';
import {useDispatch, useSelector} from 'react-redux';
import {UpdatePeopleLink} from '../../People/peopleReducer';
import {deriveEditableLink} from '../utils';
import PhotoCaptureSheet from '../../../components/PhotoCaptureSheet';
import { showPermissionMessage } from '../../../service/flashMessage';
import Container from '../../../components/Container';

const EditUnitLink = ({route, navigation}) => {
  const {unitLink} = route.params;
  const hasProfile = !!unitLink.person.id;
  const residentCanAdd = unitLink.peopleType.residentCanAdd;
  const [unitPeopleLinkState, setUnitPeopleLinkState] = useState<UnitLink>({
    ...unitLink,
  });
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [profilePanelActive, setProfilePanelActive] = useState(false);

  function updateLink() {
    setIsSubmitting(true);
    const draftState = deriveEditableLink(unitPeopleLinkState);
    dispatch(UpdatePeopleLink(unitPeopleLinkState.id, draftState)).finally(
      () => {
        setIsSubmitting(false);
      },
    );
  }

  return (
    <Container style={[tailwind('flex-1 mb-4')]}>
      <Navbar navigation={navigation} title={'Edit UnitLink'} />
      <ScrollView style={tailwind('mx-2 mb-4')}>
        <>
          <Text style={styles.textInputLabel}>First name</Text>
          <Input
            placeholder="Enter firstname"
            value={
              unitPeopleLinkState.firstName ? unitPeopleLinkState.firstName : ''
            }
            onChangeText={text =>
              setUnitPeopleLinkState({...unitPeopleLinkState, firstName: text})
            }
            isDisabled={ hasProfile ? true : !residentCanAdd }
          />
        </>
        <>
          <Text style={styles.textInputLabel}>Last name</Text>
          <Input
            placeholder="Enter lastname"
            value={
              unitPeopleLinkState.lastName ? unitPeopleLinkState.lastName : ''
            }
            onChangeText={text =>
              setUnitPeopleLinkState({...unitPeopleLinkState, lastName: text})
            }
            isDisabled={ hasProfile ? true : !residentCanAdd }
          />
        </>
        <>
          <Text style={styles.textInputLabel}>Email</Text>
          <Input
            placeholder="Enter email"
            value={unitPeopleLinkState.email ? unitPeopleLinkState.email : ''}
            onChangeText={text =>
              setUnitPeopleLinkState({...unitPeopleLinkState, email: text})
            }
            isDisabled={hasProfile}
          />
        </>
        <>
          <Text style={styles.textInputLabel}>Phone</Text>
          <Input
            placeholder="Enter phone"
            value={unitPeopleLinkState.phone ? unitPeopleLinkState.phone : ''}
            onChangeText={text =>
              setUnitPeopleLinkState({...unitPeopleLinkState, phone: text})
            }
            isDisabled={hasProfile}
          />
        </>
        <>
          <Text style={styles.textInputLabel}>Unit nickname</Text>
          <Input
            placeholder="Enter unit nickname"
            value={
              unitPeopleLinkState.unitNickname
                ? unitPeopleLinkState.unitNickname
                : ''
            }
            onChangeText={text =>
              setUnitPeopleLinkState({
                ...unitPeopleLinkState,
                unitNickname: text,
              })
            }
            isDisabled={ hasProfile ? true : !residentCanAdd }
          />
        </>
        <>
          <Text style={styles.textInputLabel}>Address Line 1</Text>
          <Input
            placeholder="Enter Address Line 1"
            value={
              unitPeopleLinkState.addressLine1
                ? unitPeopleLinkState.addressLine1
                : ''
            }
            onChangeText={text =>
              setUnitPeopleLinkState({
                ...unitPeopleLinkState,
                addressLine1: text,
              })
            }       
            isDisabled={ hasProfile ? true : !residentCanAdd }
          />
        </>
        <>
          <Text style={styles.textInputLabel}>Address Line 2</Text>
          <Input
            placeholder="Enter Address Line 2"
            value={
              unitPeopleLinkState.addressLine2
                ? unitPeopleLinkState.addressLine2
                : ''
            }
            onChangeText={text =>
              setUnitPeopleLinkState({
                ...unitPeopleLinkState,
                addressLine2: text,
              })
            }
            isDisabled={ hasProfile ? true : !residentCanAdd }
          />
        </>
        <>
          <Text style={styles.textInputLabel}>City</Text>
          <Input
            placeholder="Enter city"
            value={unitPeopleLinkState.city ? unitPeopleLinkState.city : ''}
            onChangeText={text =>
              setUnitPeopleLinkState({...unitPeopleLinkState, city: text})
            }
            isDisabled={ hasProfile ? true : !residentCanAdd }
          />
        </>
        <>
          <Text style={styles.textInputLabel}>State</Text>
          <Input
            placeholder="Enter state"
            value={unitPeopleLinkState.state ? unitPeopleLinkState.state : ''}
            onChangeText={text =>
              setUnitPeopleLinkState({...unitPeopleLinkState, state: text})
            }
            isDisabled={ hasProfile ? true : !residentCanAdd }
          />
        </>
        <>
          <Text style={styles.textInputLabel}>Zip</Text>
          <Input
            placeholder="Enter zip"
            value={unitPeopleLinkState.zip ? unitPeopleLinkState.zip : ''}
            onChangeText={text =>
              setUnitPeopleLinkState({...unitPeopleLinkState, zip: text})
            }
            isDisabled={ hasProfile ? true : !residentCanAdd }
          />
        </>
        <Permissions
          initialPeopleState={unitPeopleLinkState}
          updateParentState={childState => {
            setUnitPeopleLinkState({
              ...childState,
              firstName: unitPeopleLinkState.firstName,
              lastName: unitPeopleLinkState.lastName,
              city: unitPeopleLinkState.city,
              zip: unitPeopleLinkState.zip,
              state: unitPeopleLinkState.state,
            })
          }
          }
          isEditMode={false}
          hasUpdateButton={false}
        />
        <>
          <Text style={styles.textInputLabel}>Guard Notes</Text>
          <Input
            placeholder="Enter guard notes"
            value={
              unitPeopleLinkState.guardNotes
                ? unitPeopleLinkState.guardNotes
                : ''
            }
            multiline
            onChangeText={text =>
              setUnitPeopleLinkState({...unitPeopleLinkState, guardNotes: text})
            }
            isDisabled={ hasProfile ? true : !residentCanAdd }
          />
        </>
        <>
          <Text style={styles.textInputLabel}>Office Notes</Text>
          <Input
            placeholder="Enter office notes"
            value={
              unitPeopleLinkState.officeNotes
                ? unitPeopleLinkState.officeNotes
                : ''
            }
            multiline
            onChangeText={text =>
              setUnitPeopleLinkState({
                ...unitPeopleLinkState,
                officeNotes: text,
              })
            }
            isDisabled={ hasProfile ? true : !residentCanAdd }
          />
        </>
        <>
          <Text style={styles.textInputLabel}>Access instructions</Text>
          <Input
            placeholder="Enter access instructions"
            value={
              unitPeopleLinkState.accessInstructions
                ? unitPeopleLinkState.accessInstructions
                : ''
            }
            multiline
            onChangeText={text =>
              setUnitPeopleLinkState({
                ...unitPeopleLinkState,
                accessInstructions: text,
              })
            }
          />
        </>
        <View>
          <Text style={styles.textInputLabel}>Profile picture</Text>
          <TouchableOpacity
            onPress={() =>  {
              const pictureEditable = hasProfile ? true : !residentCanAdd
              if(!pictureEditable) {
                setProfilePanelActive(true)
              }
              else {
                showPermissionMessage();
              }
            }}
            style={styles.pictureUploadContainer}>
            {unitPeopleLinkState.profilePicture ? (
              <Image
                source={{
                  uri: `data:image/jpeg;base64,${unitPeopleLinkState.profilePicture}`,
                }}
                style={tailwind('rounded w-32 h-24')}
              />
            ) : (
              <Icon name="image" size={24} style={styles.pictureUploadIcon} />
            )}
          </TouchableOpacity>
        </View>
        <PhotoCaptureSheet
          isPanelActive={profilePanelActive}
          setIsActive={(val: boolean) => setProfilePanelActive(val)}
          onConfirmImage={imgData =>
            setUnitPeopleLinkState({
              ...unitPeopleLinkState,
              profilePicture: imgData,
            })
          }
        />
        <View style={tailwind('flex-row justify-between  pt-1 pb-3')}>
          <Text style={styles.checkBoxLabel}>Disabled ?</Text>
          <AsterixSwitch
            value={unitPeopleLinkState.canViewLog}
            color={'#007bff'}
            disabled={true}
          />
        </View>
        <DisabledInput
          title="Disabled date"
          value={unitPeopleLinkState?.disabledDate}
        />
        <>
          <Text style={styles.textInputLabel}>Disabled reason</Text>
          <Input
            placeholder="Enter disabled reason"
            value={unitPeopleLinkState?.disabledreason}
            multiline
          />
        </>
      </ScrollView>
      <Button
        onPress={() => {
          updateLink();
        }}
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
        style={tailwind('mx-2 mb-8')}
        py={4}
        isLoadingText="Submitting ...">
        {'Update '}
      </Button>
    </Container>
  );
};

export default EditUnitLink;
