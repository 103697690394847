//@ts-nocheck
import React, { useCallback, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import FileManager, {
  FileManagerTypes,
  Permissions,
  Item,
} from 'devextreme-react/file-manager';
import { Popup } from 'devextreme-react/popup';

import '../styles.css';
import {
  createFolder,
  filesResponse,
  fileTreeType,
  getSubFolders,
  newFolder,
  deleteFolderType,
  deleteFolder,
  uploadFile,
  uploadFileType,
  getFiles,
  renameFileType,
  renameFile,
  moveItems,
  moveItemsType,
} from '../data';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { useStyles } from '../styles';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedDocumentsLocation } from '../reducer';
import { convertFileToBase64, updateFolderByPath } from '../helper';

import  FileViewer  from '../components/FileViewer';
import { RootStore } from '../../../redux/store';
import Container from '../../../components/Container';
import { Navbar } from '../../../components';
import { useNavigation } from '@react-navigation/native';

function Documents() {
  const styles = useStyles();
  const dispatch = useDispatch();
  const QueryClient = useQueryClient();
  const navigation = useNavigation();

  const currentUnitLink = useSelector(
    (state: RootStore) =>
      state.units.unitLinks.filter(link => link.isDefault === true)[0],
  );
  const unitId = currentUnitLink.unit.id;
  const selectedDocumentsLocation = useSelector(
    (state: RootStore) =>
      state.documents.selectedDocumentsLocation,
  );

  const currentFileSystem = selectedDocumentsLocation?.currentFileSystem || [];
  const currentAbsolutePath = selectedDocumentsLocation?.currentAbsolutePath;

  const [popupVisible, setPopupVisible] = useState(false);
  const [fileIsLoading, setFileIsLoading] = useState(false);

  const [itemToDisplay, setItemToDisplay] = useState<{
    name?: string;
    url?: string;
    file?: Blob;
  }>({});

  const displayFile = async (e: FileManagerTypes.SelectedFileOpenedEvent) => {
    setItemToDisplay({
      name: e.file.name,
      url: e.file.path,
    });
    setPopupVisible(true);
    setFileIsLoading(true);

    try {
      const res = await getFiles({
        unitId: unitId,
        relativeLocation: e.file.path || '',
      });

      setItemToDisplay({
        name: e.file.name,
      url: e.file.path,
        file: res,
      });
    } catch (error) {}
    finally { 
      setFileIsLoading(false);
    }
  };

  const hideImagePopup = useCallback(() => {
    setPopupVisible(false);
  }, [setPopupVisible]);

  const onCurrentDirectoryChanged = (
    e: FileManagerTypes.CurrentDirectoryChangedEvent
  ) => {
    console.log('current path', e.component.option('currentPath'));
    dispatch(
      setSelectedDocumentsLocation({
        ...selectedDocumentsLocation,
        currentAbsolutePath: e.component.option('currentPath') ?? '/',
      })
    );
  };

  const {} = useQuery<filesResponse[]>(
    ['GetSubFolders', unitId, currentAbsolutePath],
    () =>
      getSubFolders({
        unitId: unitId,
        relativeLocation: currentAbsolutePath,
      }),
    {
      enabled: !!unitId,
      onSuccess: fetchedData => {
        const childFiles: fileTreeType[] = fetchedData.map(item => {
          return {
            name: item.name,
            isDirectory: item.isFolder,
            path: item.path,
            permission: item.permission,
          };
        });
        console.log('childFiles', childFiles, currentAbsolutePath);
        //create a file tree based on the current path.
        //sore in fileSystem state
        // if current path is / then the file tree will be the root folders
        //nest subfolders in the file tree based on the current path as items[]
        // if current path is /folder1 then the file tree will be the subfolders of folder1 and so on

        if (currentAbsolutePath === '/' && childFiles.length > 0) {
          //set the childFiles as the root folders
          dispatch(
            setSelectedDocumentsLocation({
              ...selectedDocumentsLocation,
              currentFileSystem: childFiles,
            }),
          );
        }

        // find the folder in the file tree object that matches the current path
        //set the items[] of that folder to the childFiles
        if (currentAbsolutePath !== '/' && childFiles.length > 0) {
          const newFileStructure = updateFolderByPath({
            currentFileTree: currentFileSystem,
            currentAbsolutePath: currentAbsolutePath,
            newFileTree: childFiles,
          });

          dispatch(
            setSelectedDocumentsLocation({
              ...selectedDocumentsLocation,
              currentFileSystem: newFileStructure,
            }),
          );
        }
      },
    },
  );


  const { mutate } = useMutation(
    (newFolder: newFolder) => createFolder(newFolder),
    {
      onSuccess: async data => {
        // On success, refetch the data
        QueryClient.invalidateQueries({
          queryKey: ['GetSubFolders'],
        });
      },
    }
  );

  //uploadFile
  const { mutate: upload } = useMutation(
    (file: uploadFileType) => uploadFile(file),
    {
      onSuccess: async data => {
        // On success, refetch the data
        QueryClient.invalidateQueries({
          queryKey: ['GetSubFolders'],
        });
      },
    }
  );

  //delete folder
  const { mutate: deleteDir } = useMutation(
    (folder: deleteFolderType) => deleteFolder(folder),
    {
      onSuccess: async data => {
        // On success, refetch the data
        QueryClient.invalidateQueries({
          queryKey: ['GetSubFolders'],
        });
      },
    }
  );

  //rename files
  const { mutate: rename } = useMutation(
    (file: renameFileType) => renameFile(file),
    {
      onSuccess: async data => {
        // On success, refetch the data
        QueryClient.invalidateQueries({
          queryKey: ['GetSubFolders'],
        });
      },
    }
  );
  //move
  const { mutate: move } = useMutation(
    (file: moveItemsType) => moveItems(file),
    {
      onSuccess: async data => {
        // On success, refetch the data
        QueryClient.invalidateQueries({
          queryKey: ['GetSubFolders'],
        });
      },
    }
  );
  const onDirectoryCreating = (e: FileManagerTypes.DirectoryCreatingEvent) => {
    const newFolder: newFolder = {
      unitId: unitId,
      relativeLocation: currentAbsolutePath,
      newFolderName: e.name,
      // permission: 1,
    };
    mutate(newFolder);
  };

  const onItemDeleting = (e: FileManagerTypes.ItemDeletedEvent) => {
    const lastSlashIndex = e.item.key.lastIndexOf('/');
    const newPath = e.item.key.substring(0, lastSlashIndex);

    //delete folder
    const folder: deleteFolderType = {
      unitId: unitId,
      relativeLocation: newPath,
      itemToDelete: e.item.name,
      forceDelete: true,
    };

    deleteDir(folder);
  };

  //upload file
  const onFileUploading = async (e: FileManagerTypes.FileUploadedEvent) => {
    //get file data and convert to base64
    const file = e.fileData;
    const base64 = await convertFileToBase64(file);
    const data: uploadFileType = {
      unitId: unitId,
      relativeLocation: currentAbsolutePath,
      fileName: file.name,
      permission: 1,
      base64Contents: base64,
    };

    upload(data);
  };
  //on rename
  const onRename = (e: FileManagerTypes.ItemRenamingEvent) => {
    const file: renameFileType = {
      unitId: unitId,
      relativeLocation: currentAbsolutePath,
      itemToRename: e.item.name,
      newName: e.newName,
    };
    rename(file);
  };

  //on move

  const onMove = (e: FileManagerTypes.ItemMovingEvent) => {
    const event: FileManagerTypes.ItemMovingEvent & {
      item: { parentPath: string };
    } = e as any;
    const file: moveItemsType = {
      unitId: unitId,
      itemsToMove: [event.item.name],
      itemsToMoveRelativePath: event.item.parentPath,
      destinationDirectoryRelativePath: e.destinationDirectory.path,
    };
    move(file);
  };

  //on copy
  const onCopy = (e: FileManagerTypes.ItemCopyingEvent) => {
    const event: FileManagerTypes.ItemMovingEvent & {
      item: { parentPath: string };
    } = e as any;

    console.log('copy event', event);
    const file: moveItemsType = {
      unitId: unitId,
      itemsToMove: [event.item.name],
      itemsToMoveRelativePath: event.item.parentPath,
      destinationDirectoryRelativePath: e.destinationDirectory.path,
      action: 'copy',
    };
    move(file);
  };

  return (
    <Container hasDrawer={true}>
      <Navbar navigation={navigation} title="Documents" />
      <FileManager
        currentPath={currentAbsolutePath}
        fileSystemProvider={currentFileSystem}
        onSelectedFileOpened={displayFile}
        onCurrentDirectoryChanged={onCurrentDirectoryChanged}
        onDirectoryCreating={onDirectoryCreating}
        onItemDeleted={onItemDeleting}
        rootFolderName={'Documents'}
        onFileUploaded={onFileUploading}
        onItemRenaming={onRename}
        onItemMoving={onMove}
        onItemCopying={onCopy}
        hoverStateEnabled={true}
        itemView={{
          showFolders: true,
        }}>
        <Permissions
          create={true}
          copy={true}
          move={true}
          delete={true}
          rename={true}
          upload={true}
          download={true}
        />
        <Item />
      </FileManager>
      <Popup
        maxHeight={800}
        hideOnOutsideClick={true}
        showCloseButton={true}
        title={itemToDisplay.name}
        visible={popupVisible}
        onHiding={hideImagePopup}
        className="photo-popup-content">
        {fileIsLoading && (
          <Box className={styles.loader}>
            <CircularProgress size="50px" />
          </Box>
        )}
        {itemToDisplay.file && (
          <FileViewer
            file={itemToDisplay.file}
            name={itemToDisplay.name || ''}
          />
        )}
      </Popup>
    </Container>
  );
}

export default Documents;